
import { PRUEBAFETCH, LOGIN_FAIL, LOGIN_TRY, LOGIN_SUC, UPDATEPASSWORD_FAIL, UPDATEPASSWORD_SUC, UPDATEPASSWORD_TRY, CERRAR_SESSION ,SET_ANIO} from '../Constants';
import { config } from '../config';
import decode from 'jwt-decode';

import { toast } from 'react-toastify';

let loginEndpoint = `${config.login.IP}`
let passwordEndpoint = `${config.users.IP}`

export function addArticle(payload) {
  return { type: "ADD_ARTICLE", payload }
};

export function receiveStuff(json) {
  return { type: PRUEBAFETCH, stuff: json.stuff };
}

export function seteoAnio(anio) {
  return {
    type: SET_ANIO,
    loading: false,
    anio: anio,
    error: true
  }
}

export function loginFail() {
  return {
    type: LOGIN_FAIL,
    loading: false,
    authenticated: false,
    error: true
  }
}

export function loginTry() {
  return {
    type: LOGIN_TRY,
    loading: true,
    authenticated: false,
  }
}

export function loginSucc(key) {
  localStorage.setItem("token", key.key);
  localStorage.setItem("cred", key.credentials);
  const expirationDate = new Date(new Date().getTime() + 1000 * 1000);
  localStorage.setItem('expirationDate', expirationDate);
  return {
    type: LOGIN_SUC,
    loading: false,
    authenticated: true,
    key:'xzhkjhsfkjdhkdsjh',
    username: key.credentials.mail,
    role: key.role
    //pswdReset: 'sdfhdsjhfk'
    /*username: decoded.username,
    role: decoded.role,
    pswdReset: decoded.pswdReset*/
  }
}

export function passwordUpdateFail() {
  return {
    type: UPDATEPASSWORD_FAIL,
    loading: false
  }
}

export function passwordUpdateTry() {
  return {
    type: UPDATEPASSWORD_TRY,
    loading: true,
  }
}

export function passwordUpdateSucc() {
  return {
    type: UPDATEPASSWORD_SUC,
    loading: false,
    pswdReset: false
  }
}

export function loginAttemp(credentials) {
  return dispatch => {
    dispatch(loginTry());
    var myInit = {
      method: 'POST',
      headers: {
        'accept':'*/*',
        'Content-Type':'application/json'
      },
      body: JSON.stringify(credentials),
      mode: 'cors',
      cache: 'default'
    }
    if(credentials.mail==='erick2'){
      let obj = {
        key: 'xzhkjhsfkjdhkdsjh',
        credentials: credentials,
        activo:true,
        mail:"erick2",
        role:"ADMN"
      }
        //dispatch(checkAuthTimeout(20))
        dispatch(loginSucc(obj))
    }else{
      return fetch(loginEndpoint + "/UsuarioAnuario/authenticate", myInit)
      .then(response => response.json())
      .then(json => {
        
        localStorage.setItem('token',json.info.token);
        localStorage.setItem('us',json.info.idUsuario);
        let obj = {
          key: json.info.token,
          credentials: credentials,
          activo: json.info.activo,
          mail:json.info.mail,
          role:json.info.esAdmin?"ADMN":"USR"
        }
        if(!json.resultado){
          dispatch(loginFail())
        }else{
          dispatch(checkAuthTimeout(10))
          toast.success('Bienvenido', { 
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            closeButton: null
        });
          dispatch(loginSucc(obj))
        }
      })
      .catch(err => {
        console.log("Error en el usuario")
        toast.error('Usuario incorrecto', { 
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          closeButton: null
      });
        console.log(err)
       
      });
      
    }
    
  };
}

export function cerrarSesion() {
  localStorage.removeItem("token");
  localStorage.removeItem("cred");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("anio");
  localStorage.clear();
  return {
    type: CERRAR_SESSION,
    authed: false,
    key: ''
  };
}

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      //dispatch(authCheckState())
      //dispatch(cerrarSesion());
    }, expirationTime * 10000)
  }
}


export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    let obj = {
      key: token,
      credentials: localStorage.getItem("cred")
    }
    if (localStorage.token === undefined || localStorage.token === null) {
      dispatch(cerrarSesion());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate <= new Date()) {
        console.log("volver a iniciar sesion")
        dispatch(cerrarSesion())
      } else {
        console.log("debe de estar autenticado")
        //dispatch(loginAttemp(obj.credentials))
      }
    }
  }
}

export const updatePassword = (bodyPayload) => {
  return dispatch => {
    dispatch(passwordUpdateTry());
    return fetch(passwordEndpoint + "change-password", {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("token"),
      },
      body: JSON.stringify(bodyPayload),
      mode: "cors",
      cache: 'default',
    })
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          dispatch(passwordUpdateSucc())
        } else {
          dispatch(passwordUpdateFail())
        }
      })
      .catch(err => dispatch(passwordUpdateFail()));
  }
}

export const resetUser = (bodyPayload) => {
  return dispatch => {
    dispatch(passwordUpdateTry());
    return fetch(passwordEndpoint + "reset-user", {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("token"),
      },
      body: JSON.stringify(bodyPayload),
      mode: "cors",
      cache: 'default',
    })
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          dispatch(passwordUpdateSucc())
        } else {
          dispatch(passwordUpdateFail())
        }
      })
      .catch(err => dispatch(passwordUpdateFail()));
  }
}