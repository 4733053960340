import { createStore ,compose, applyMiddleware} from "redux";
import rootReducer from "../Reducers/rootReducer";
import thunk from 'redux-thunk';


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
 function configureStore(){
    return createStore(
        rootReducer,
        composeEnhancer(applyMiddleware(thunk)),
    );
}


export default configureStore