import React from 'react';
import password from '../../assets/contraseña/icon.svg';
import { updatePassword } from '../../Actions'
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import './index.css';

import CustomNavbar from '../Navbar'
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, FormFeedback } from 'reactstrap';

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      newPassVisible: false,
      confirmPassword: '',
      confirmPassVisible: false,
      error: false
    }
  }


  onSubmit = () => {
    let { newPassword, confirmPassword } = this.state;
    let { username } = this.props;

    if (newPassword !== confirmPassword) {
      return this.setState({
        error:true
      })
    }

    this.props.updatePassword({ newPassword });
    let cred = btoa(username + ":" + newPassword);
    localStorage.setItem("cred", cred);

  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  showNewPassword = () => {
    let { newPassVisible } = this.state;
    this.setState({ newPassVisible: !newPassVisible })
  }

  showConfirmPassword = () => {
    let { confirmPassVisible } = this.state;
    this.setState({ confirmPassVisible: !confirmPassVisible })
  }

  handleKeyPress = (target) => {
    if (target.charCode === 13) {
      this.onSubmit()
    }
  }
  
  render() {
    let styles = {
      color: "white",
      bColor: "77d700",
      darkk: false
    }

    let { error, newPassVisible, confirmPassVisible } = this.state;

    if (!this.props.authed) {
      return <Redirect to="/login" />
    }

    if (!this.props.pswdReset) {
      return <Redirect to="/inicio" />
    }

    return (
      <div >
        <Container fluid>
          <Row>
            <Col>
              <CustomNavbar color={styles.color} bColor={styles.color} darkk={styles.dark} authed={this.props.authed} />
            </Col>
          </Row>
          <Container className="loginBox" style={{ paddingTop: "200px", height: "100%" }} >

            <Row className="justify-content-center" >
              <Col md="6" style={{ top: "22%" }}>
                <CardGroup>

                  <Card className="p-4 loginBox" style={{ paddingTop: "200px", backgroundColor: "#006741", borderRadius: "10px" }}>
                    <CardBody>
                      <Form style={{ color: "white", textAlign: "center" }}>

                        <h2>Nueva Contraseña</h2>
                        <p style={{ color: "white" }}>Ingresa los siguientes datos</p>

                        <InputGroup className="mb-3" >

                          <Input type={newPassVisible ? "text" : "password"} placeholder="Nueva contraseña" autoComplete="current-password" name="newPassword" value={this.state.newPassword} onChange={this.onChange} invalid={error} />

                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="inputsRagasaImg"  >
                              <img src={password} alt="uno" onClick={this.showNewPassword} />
                            </InputGroupText>
                          </InputGroupAddon>

                        </InputGroup>

                        <InputGroup className="mb-4">

                          <Input type={confirmPassVisible ? "text" : "password"} onKeyPress={this.handleKeyPress} placeholder="Confirmar contraseña" name="confirmPassword" value={this.state.confirmPassword} onChange={this.onChange} invalid={error} />

                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="inputsRagasaImg"  >
                              <img src={password} alt="uno" onClick={this.showConfirmPassword} />
                            </InputGroupText>
                          </InputGroupAddon>

                          <FormFeedback invalid style={{ fontWeight: "bold", color: "#fb5f5f" }}>
                            Las contraseñas no coinciden
                        </FormFeedback>

                        </InputGroup>

                        <Row style={{ color: "#77d700", borderTop: "2px solid " }}>
                          <Col sm="12" md={{ size: 6, offset: 3 }} style={{ paddingTop: "5px" }}>
                            <Button id="loginButton" className="loginButton" onClick={this.onSubmit} disabled={false}>Acceder</Button></Col>
                        </Row>

                      </Form>
                    </CardBody>
                  </Card>

                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12" >
                <p className="brand">Powered by Insaite</p>
              </Col>

            </Row>

          </Container>

        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authed: state.login.authed,
    username:  state.login.username,
    pswdReset: state.login.pswdReset
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updatePassword: (newPassword) => dispatch(updatePassword(newPassword)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Password);