import Loadable from 'react-loadable'
import Loading from '../../Views/Loading';

const Usuario = Loadable({
    loader: () => import('../../Views/Usuario'),
    loading: Loading,
  });


  const Usuarios = Loadable({
    loader: () => import('../../Views/Usuarios'),
    loading: Loading,
  });
  const Contacto = Loadable({
    loader: () => import('../../Views/Contacto'),
    loading: Loading,
  });
  const Editar = Loadable({
    loader: () => import('../../Views/UsuarioEditar'),
    loading: Loading,
  });
  const Password = Loadable({
    loader: () => import('../../Views/UsuarioPassword'),
    loading: Loading,
  });

  const Accesos = Loadable({
    loader: () => import('../../Views/Accesos'),
    loading: Loading,
  });

const routes = [
    { path:'/inicio',name:"Inicio", component: Usuario },
    { path:'/users',name:"Usuarios", component: Usuarios },
    { path:'/contacto',name:"Contacto", component: Contacto },
    { path:'/editar',name:"Editar", component: Editar },
    { path:'/accesos',name:"Editar", component: Accesos },
    { path:'/restablecer',name:"Editar", component: Password }
]

export default routes;