import React from 'react';
import { Modal,  ModalBody, ModalFooter } from 'reactstrap';
import {connect} from 'react-redux';
import {Grid,Col,Row} from 'react-flexbox-grid';
import {cambiaModalGlosario} from '../../Actions/Modal'
import botonCerrar from '../../assets/botonCerrar/icon.svg';
import './index.css'

class ModalGlosario extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        modal: this.props.modal,
        HTML:''
      };
  
      this.toggle = this.toggle.bind(this);
    }
  
    toggle() {
      let modal = this.props.modal;
      this.props.modalVariable(!modal);
      this.setState({modal:this.props.modal})
    }
    componentDidMount = () => {
      fetch(`./data/nav/estadistica-mundial/result.htm`).then(r => {
        r.text().then(t =>{
          console.log("got it")
            this.setState({HTML:t});
        })
    }
    ).catch((ex)=>{
        console.log("Error")
        console.log(ex)
    });
    } 
  
    render() {
      let modal = this.props.modal;
      let {HTML} = this.state;
      
      return (
        <div>
          <Modal isOpen={modal} className='estadistica' >
         
          <div className="headerContent">
            <Grid >
            <Row>
              <Col xs={12}>
                <Row end="xs">
                  <Col xs={15} /><img  style={{cursor:"pointer"}}src={botonCerrar} alt="ing" onClick={this.toggle} />
                </Row>
              </Col>
            </Row> 
            </Grid>
            </div>

            <ModalBody className="scrollModal">
              <div dangerouslySetInnerHTML={{__html:HTML }}/>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
  const mapStateToProps = state => {
    return {
      modal : state.modalReducer.modalGlos
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      modalVariable:(modal)=> dispatch(cambiaModalGlosario(modal)) 
    }
  }

export default connect (mapStateToProps,mapDispatchToProps)(ModalGlosario);
