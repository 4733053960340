import React, { Component } from 'react';
import './App.css';
import Layout from './Views/Layout'
import Login from './Views/Login'
import Password from './Views/Password'
import './index.css'
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authCheckState } from './Actions';


import 'react-toastify/dist/ReactToastify.css';

toast.configure()

class App extends Component {
  render() {
    this.props.otro()
    return (
      <div className="global">
        <HashRouter>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/password" component={Password} />
            <Route path="/" component={Layout} />
            <Route path="/data/*"/>
            <Route path="/Excel/*"/>
          </Switch>
        </HashRouter>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: localStorage.getItem('isAuthenticated')//state.token !== null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    otro: () => dispatch(authCheckState(15))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);