import React from 'react';
import { Container, Row, Col, Fade } from 'reactstrap';

import CustomNavbar from '../Navbar';
import { Route, Switch, Redirect } from 'react-router';
import { connect } from 'react-redux';
import RoutesUser from '../../Routes/USER';

import rutasAdmin from '../../Routes/ADMIN';
import './index.css'

class Layout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      login: false,
      redirect: false,
      role: "",
      regresar :false
    }
  }

  componentDidMount = () => {
    let {authed,role} =this.props;
    if (!authed) {
      this.setState({ redirect: true });
    }else{
      
      this.setState({ role: role })
    }
    
  }

  render() {
   let { role,regresar } = this.state;
    let styles = {
      login: {
        color: "white",
        bColor: "77d700",
        darkk: true
      }
    }
    let rutas ="";
    if (role==='ADMN'){
      rutas = rutasAdmin;
     }else{
       rutas = RoutesUser;
     }

    if (this.props.location.pathname === "/") {
      return (<Redirect to="/inicio" />)
    }

    if (!this.props.authed) {
      rutas = [];
      return <Redirect to="/login" />
    }
   

    return (
      <div>
        <Container fluid  >
          <Row >
            <Col md={12}>
              <CustomNavbar color={styles.login.color} bColor={styles.login.bcolor} darkk={styles.login.darkk} authed={this.props.authed} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Fade>
                <Switch>
                  {rutas.map((route, idx) => {
                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                      <route.component {...props} />
                    )} />)
                      : (null);
                  },
                  )}
                </Switch>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authed: state.login.authed,
    pswdReset: state.login.pswdReset,
    role : state.login.role
  }
}

const mapDispatchToProps = dispatch => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);