export const config = {
    stream: {
        IP: "https://76k78gl3p4.execute-api.us-east-1.amazonaws.com/dev/",
        PORT: "3300"
    },
    login: {
        IP: "https://comscorebackend20201222105659.azurewebsites.net",
        PORT: ""
    },
    users: {
        IP: "https://comscorebackend20201222105659.azurewebsites.net",
        PORT: ""
    },
    sim: {
        IP: "https://76k78gl3p4.execute-api.us-east-1.amazonaws.com/dev/",
        PORT: ""
    },
    bitacora: {
        IP: "https://76k78gl3p4.execute-api.us-east-1.amazonaws.com/dev/",
    }
}

