import React from 'react';
// First way to import
import { ClipLoader } from 'react-spinners';
// Another way to import
import { connect } from 'react-redux'


class Loading extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  componentDidMount = () => {
    this.setState({ loading: this.props.loading })
  }

  componentWillReceiveProps = (newProps) => {
    let { loading } = this.state;
    if (loading !== newProps.loading) {
      this.setState({ loading: newProps.loading })
    }
  }

  render() {
    return (
      <div style={{ display: "inherit" }} >
        <ClipLoader
          sizeUnit={"px"}
          size={20}
          color={'#123abc'}
          loading={this.state.loading}
        />
      </div>
    )
  }
}

const stateToProps = state => {
  return {
    //loading: state.streamReducer.REC.loading
  }
}

export default connect(stateToProps, null)(Loading)