import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import directory from '../../Directory/directory.json';
import { Link, Redirect } from 'react-router-dom';
import {setBusqueda} from '../../Actions/Busqueda'
import {connect} from 'react-redux';
import './index.css';

class Busqueda extends Component {

  constructor(props) {
    super(props);

    this.state = {
      val: "",
      bus: [],
      graph: false
    }

  }
  componentDidMount = () => {
    let obj = []
    let anio = localStorage.getItem("anio");
    let content = directory.filter(x => x.year == anio).map(x => x.content)[0]
    content.map((x, idx) => {
      x.children.map((y, idx) => {
        y.children.map((z, idx) => {
          obj.push({
            name: z.name,
            relative: z.relative,
            city: z.relative.split("/")[0],
            submenu: z.relative.split("/")[1]
          })
        })
      })
    })
    this.setState({ bus: obj })

  }
  renderResponse = (state, valor) => {
    let res  = state.relative.toString().toLowerCase().indexOf(valor.toString().toLowerCase()) !== -1
    return res
  }
  setValue = (val) => {
    localStorage.setItem("bus", true)
    localStorage.setItem("busItem", val.relative)
    this.setState({ graph: true })
    this.props.buscar(true)
    //this.setState({val:val.name})

  }
  change = (val) => {
    this.setState({val:val.target.value})
  }

  render() {
    let { val, bus, graph } = this.state;
    return (

      <div className="autocomplete-wrapper">
        <Autocomplete
          value={val.toString()}
          items={bus}
          getItemValue={item => item.relative.toString()}
          shouldItemRender={this.renderResponse}
          renderMenu={item => (
            <div className="dropdown">
              {item}
            </div>
          )}
          should
          renderItem={(item, isHighlighted) =>
             {
              let nom = item.name.replace(/_/g, ' ')
              let gname = nom.charAt(0).toUpperCase() + nom.slice(1)
               return (<div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
               <Link to="/graph" className="item" >{gname} - {item.city.toUpperCase()}</Link>
             </div>)

          }
            
          }
          onChange={this.change}
          onSelect={(event, val) => this.setValue(val)}
        />
      </div>
    );
  }
}


const stateToProps = state => {
  return {
    busquea : state.busqueda
  }
}

const dispatchToProps = dispatch => {
  return { 
      buscar : (bus)=> dispatch(setBusqueda(bus))
  }
}
export default connect(stateToProps, dispatchToProps)(Busqueda);