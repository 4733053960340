import {MODAL_ESTADISTICA,MODAL_ANALITICA,MODAL_VARIABLES,MODAL_REF
    ,MODAL_LOGIN,RES_MODAL,RES_DAT,MODAL_ANOT,MODAL_NOTA,MODAL_GLOSARIO,DATA_ANOT,DATA_NOTA,DATA_REF,SET_ANALITIC,SET_ESTAD, MODAL_MARC}  from '../../Constants';
import { config } from '../../config';
let endpoint = `${config.login.IP}`

export  function cambiaModalAnalitica (modal) {
    return {
        type:MODAL_ANALITICA,
        modal:modal
    }
}



export  function cambiaModalGlosario (modal) {
    return {
        type:MODAL_GLOSARIO,
        modal:modal
    }
}

export  function cambiaModalMarc (modal) {
    return {
        type:MODAL_MARC,
        modal:modal
    }
}

export  function cambiaModalEstadistica (modal) {
    return {
        type:MODAL_ESTADISTICA,
        modal:modal
    }
}

export  function modalNotas (modal) {
    return {
        type:MODAL_NOTA,
        modal:modal
    }
}

export  function modalRef (modal) {
    return {
        type:MODAL_REF,
        modal:modal
    }
}
export  function dataAnot (modal) {
    return {
        type:DATA_ANOT,
        anot:modal
    }
}

export  function dataRef (modal) {
    return {
        type:DATA_REF,
        ref:modal
    }
}

export  function dataNotas (modal) {
    return {
        type:DATA_NOTA,
        nota:modal
    }
}

export  function modalAnot (modal) {
    return {
        type:MODAL_ANOT,
        modal:modal
    }
}

export  function cambiaModalVariables (modal) {
    return {
        type:MODAL_VARIABLES,
        modal:modal
    }
}

export  function cambiaModalSesion (modal) {
    return {
        type:MODAL_LOGIN,
        modal:modal
    }
}

export function openRes (modal) {
    return {
        type:RES_MODAL,
        modal:modal
    }
}

export function resDat (modal){
    return {
        type:RES_DAT,
        modal: modal
    }
}

export function setAnalisisData (datos){
    return {
        type:SET_ANALITIC,
        archivo: datos
    }
}

export function setEstadisticaData (datos){
    return {
        type:SET_ESTAD,
        archivo: datos
    }
}

export function obtieneNotas(mod) {
    let url = endpoint + "/NotaReporte/"+mod+"/anio"+localStorage.getItem("anio");
    return dispatch => {
        fetch(endpoint + "/NotaReporte/"+mod, {
            method: 'GET',
            headers : {
                "Content-Type":"application/json"
            },
            mode: "cors",
            cache: 'default'
        })
            .then(async data => {
                return await data.json();
            }).then(json => {
                if (json.resultado) {
                    dispatch(dataNotas(json.info));
                }
            })
            .catch(err => {
                console.log(err);
            }
            );
    }
}


export function obtieneRef(mod) {

    return dispatch => {
        fetch(endpoint + "/ReferenciaReporte/"+mod, {
            method: 'GET',
            headers : {
                "Content-Type":"application/json"
            },
            mode: "cors",
            cache: 'default'
        })
            .then(async data => {
                if(data.status!=200){
                    
                dispatch(dataRef([]));
                }
                return await data.json();
            }).then(json => {
                if (json.resultado) {
                    dispatch(dataRef(json.info));
                }
            })
            .catch(err => {
                console.log("error en referencias")
                dispatch(dataRef([]));
            }
            );
    }
}

export function obtieneAnot(obj) {

    return dispatch => {

        let url = "https://comscorebackend20201222105659.azurewebsites.net/"
        let url2 = "https://comscorebackend20201222105659.azurewebsites.net"+"/NotaUsuario/getNota";
        fetch(url2, {
            method: 'POST',
            headers : {
                "Content-Type":"application/json"
            },
            body:JSON.stringify(obj),
            mode: "cors",
            cache: 'default'
        })
            .then(async data => {
                if(data.status!=200){
                    
                    dispatch(dataAnot([]));
                    }
                return await data.json();
            }).then(json => {
                if (json.resultado) {
                    dispatch(dataAnot(json.info));
                }
            })
            .catch(err => {
                console.log(err);
            }
            );
    }
}




export function mandaAnot(clave) {
    console.log(endpoint + "/NotaUsuario​/create")
    let url2 = endpoint + "/NotaUsuario​/create";
    let url = "https://comscorebackend20201222105659.azurewebsites.net/NotaUsuario/create"
    return dispatch => {
        fetch(url, {
            method: 'POST',
            headers : {
                "Content-Type":"application/json"
            },
            body:JSON.stringify(clave),
            mode: "cors",
            cache: 'default'
        })
            .then(async data => {
                return await data.json();
            }).then(json => {
                if (json.resultado) {
                    dispatch(dataAnot(json.info));
                }
            })
            .catch(err => {
                console.log(err);
            }
            );
    }
}
   