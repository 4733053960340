export const ADD_ARTICLE = "ADD_ARTICLE";
export const pruebaRedux = "Prueba prro";
export const LOGIN_TRY = "Intento de login";
export const LOGIN_SUC = "Correcto login";
export const LOGIN_FAIL = "Login incorrecto";
export const PRUEBAFETCH = "Prueba fetch prro";
export const MODAL_REPORTES = "Movimiento Modal Reportes";
export const MODAL_ANOT = "Modal anotacion";
export const MODAL_ESTADISTICA = "Modal estadistica";

export const MODAL_MARC = "Modal MARC";

export const DATA_ANOT = "DATA ANOT";
export const DATA_NOTA = "DATA NOTA";
export const DATA_REF = "DATA REF";
export const MODAL_GLOSARIO = "Modal Glosario";
export const MODAL_NOTA = "Modal notas";
export const MODAL_REF = "Modal ref";
export const MODAL_ANALITICA = "Modal analitica";
export const MODAL_VARIABLES = "Movimiento Modal de variables";
export const MARIA_PRUEBA = "Se intento acceder a la base de datos de maria db";
export const MARIA_SUC = "Se accedio a la base de datos de mariadb";
export const MARIA_FAIL = "fallo maria db";
export const CERRAR_SESSION = "Cerrando session";
export const MODAL_LOGIN = "Movimiento Modal Session";
export const SIM_PRUEBA = "Intento de simulacion";
export const SIM_FAIL = "error fetch simulacion";
export const SIM_SUC = "Simulacion satisfactoria";
export const VR_TRY = "Intentando obtener el valor real";
export const VR_FAIL = "Fallo al obtener el valor real";
export const VR_SUC = "Se logro obtener el valor real";
export const MODEL_TRY = "Intentando obtener la respuesta de el modelo";
export const MODEL_FAIL = "Fallo la respuesta de el modelo";
export const MODEL_SUC = "Se logro obtener respuesta de el modelo";
export const REC_TRY = "Intentando obtener el valor de recomendacion";
export const REC_FAIL = "Fallo obtener el valor de recomendacion";
export const REC_SUC = "Se logro obtener el valor de recomendacion";
export const SET_BANDA = "El valor de la banda cambio";
export const REP_TRY = "Se intento obtener el archivo";
export const REP_FAIL = "Fallo la obtencion del archivo";
export const REP_SUC = "Se obtuvo el archivo";
export const PLOT_TRY = "Se intento obtener la informacion de la grafica";
export const PLOT_FAIL = "Fallo la obtencion de la informacion de la grafica";
export const PLOT_SUC = "Se obtuvo la informacion de la grafica";
export const DEL_DAT = "Se borro la info";
export const RES_DAT = "Se restablecieron los datos";
export const RES_MODAL = "Modal restablecer";
export const LOG_MOAL = "LOG MODAL";

export const GETUSERS_TRY = "Se intento obtener los usuarios de la coleccion de mongo";
export const GETUSERS_SUC = "Se obtuvieron los usuarios mongo";
export const GETUSERS_FAIL = "Error al obtener usuarios mongo";


export const MODANUARIO_TRY = "Se intento modificar el anuario de la coleccion de mongo";
export const MODANUARIO_SUC = "Se modifico  el anuario mongo";
export const MODANUARIO_FAIL = "Error al modificar el anuario mongo";

export const GETANUARIO_TRY = "Se intento obtener el anuario de la coleccion de mongo";
export const GETANUARIO_SUC = "Se obtuvieron  el anuario mongo";
export const GETANUARIO_FAIL = "Error al obtener el anuario mongo";

export const GETANUARIOS_TRY = "Se intento obtener los anuario de la coleccion de mongo";
export const GETANUARIOS_SUC = "Se obtuvieron  los anuario mongo";
export const GETANUARIOS_FAIL = "Error al obtener los anuario mongo";

export const CREATEUSERS_TRY = "Se intento crear usuario";
export const CREATEUSERS_SUC = "Se logro crear el usuario";
export const CREATEUSERS_FAIL = "Fallo la creación del usuario";

export const EDITUSERS_TRY = "Se intento actializar usuario";
export const EDITUSERS_SUC = "Se logro actializar el usuario";
export const EDITUSERS_FAIL = "Fallo la actialización del usuario";

export const DELETEUSERS_TRY = "Se intento eliminar el usuario";
export const DELETEUSERS_SUC = "Se logro eliminar el usuario";
export const DELETEUSERS_FAIL = "Fallo al eliminar del usuario";

export const GETRATES_TRY = "Se intento obtener los tiempos de la coleccion de mongo";
export const GETRATES_SUC = "Se obtuvieron los tiempos mongo";
export const GETRATES_FAIL = "Error al obtener tiempos mongo";

export const UPDATERATES_TRY = "Se intento actualizar el rango";
export const UPDATERATES_SUC = "Se logro actializar el rango";
export const UPDATERATES_FAIL = "Fallo la actialización del rango";

export const GETBITLOG_TRY = "Se intento obtener las bitacoras de la coleccion de mongo";
export const GETBITLOG_SUC = "Se obtuvieron las bitcoras mongo";
export const GETBITLOG_FAIL = "Error al obtener bitacoras mongo";

export const GETMOREBITLOG_TRY = "Se intento obtener mas bitacoras de la coleccion de mongo";
export const GETMOREBITLOG_SUC = "Se obtuvieron mas bitcoras mongo";
export const GETMOREBITLOG_FAIL = "Error al obtener mas bitacoras mongo";

export const CREATEBITLOG_TRY = "Se intento crear bitacora";
export const CREATEBITLOG_SUC = "Se logro crear la bitacora";
export const CREATEBITLOG_FAIL = "Fallo la creacion de la bitacora";

export const UPDATEPASSWORD_TRY = "Se intento actualizar la contraseña";
export const UPDATEPASSWORD_SUC = "Se logro actualizar la contraseña";
export const UPDATEPASSWORD_FAIL = "Fallo la actualización de la contraseña";

export const RESETUSER_TRY = "Se intento restablecer la contraseña";
export const RESETUSER_SUC = "Se logro restablecer la contraseña";
export const RESETUSER_FAIL = "Fallo al restablecer la contraseña";

export const NOTIFY_TEXT = "Se notifica un texto";
export const NOTIFY_TEMPLATE = "Se notifica con un template html";


///busqueda


export const INTENTO_BUSQUEDA = "se intento hacer una busqueda";

export const BUSQUEDA_SUC = "busqueda completada";
export const BUSQUEDA_FAIL = "Error en la busqueda";


export const  SET_GRAFICA = "Set grafica"

export const  SET_NOM_GRAFICA = "Set nombre GRAFICA";

export const SET_REF = "SET REFERENCIAS"
export const SET_SECC = "SET SECC"


export const SET_ANALITIC = "DATOS ANALISIS"
export const SET_ESTAD = "DATOS ESTADISTICA"

export const SET_REGRESAR = "SET_REGRESAR"




export const SET_ANIO = "SET ANIO"