import React from 'react';
import { Button, Modal, ModalBody, Container, Row, Col } from 'reactstrap';
import PropTypes from 'proptypes';
import './index.css'
import { connect } from 'react-redux'
import { cerrarSesion } from '../../Actions';
import { cambiaModalSesion } from '../../Actions/Modal';
import { Redirect } from 'react-router-dom';

class ModalRestablecer extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      dropdownOpen: false,
      rangoHumedad: "",
      datePicker: false,
      fecha: new Date(),
      fecha1: "",
      redirect: false
    };

  }

  toggleModal = () => {
    this.props.toggleModal(!this.props.modal)
    this.setState({ modal: !this.props.modal });
  }

  toggleDrop = () => {
    let { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: !dropdownOpen });
  }

  componentWillReceiveProps = (newProps) => {
    if (this.props.modal !== newProps.modal) {
      this.setState({ modal: newProps.modal })
    }
  }

  handleChange = (date) => {
    this.setState({
      fecha: date
    });
  }

  fecha = () => {
    let { datePicker } = this.state;
    this.setState({ datePicker: !datePicker });
  }

  onSelect = (value, states) => {
    let fecha = value.start.format("YYYY-MM-DD") + " - " + value.end.format("YYYY-MM-DD");
    this.setState({ fecha1: fecha });
  };

  cerrarSess = () => {
    this.props.cerrar()
    this.props.toggleModal(!this.props.modal)
  }

  render() {

    let { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/login" />
    }

    return (
      <div>
        <Modal id="modalRestablecer" isOpen={this.props.modal} size="md">

          <div className="headerContent" style={{ textAlign: "center" }}>
            <div className="headerModalR" >
              <p className="headerModalText" ><strong>Cerrar Sesión</strong> </p>
              <p className="headerModalTextSub" style={{ color: "white" }}>¿Deseas continuar?</p>
            </div>
          </div>
          <ModalBody>
            <Container>

              <Row>
                <Col sm="6" md={{ size: 6, offset: 3 }}>
                  <Button id="botonRestablecer" onClick={this.toggleModal}>Seguir con mi sesión</Button>
                </Col>
              </Row>
              <Row>
                <Col sm="6" md={{ size: 6, offset: 3 }}>
                  <p className="text-center" style={{ padding: "20px", cursor: "pointer" }} onClick={this.cerrarSess}>Cerrar sesión</p>

                </Col>
              </Row>
            </Container>


          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ModalRestablecer.propTypes = {
  modal: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    modal: state.modalReducer.modalSession,
    auth: state.login.authed
  }
}

function mapDispatchToProps(dispatch) {
  return {
    cerrar: () => dispatch(cerrarSesion()),
    toggleModal: (modal) => dispatch(cambiaModalSesion(modal))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalRestablecer);