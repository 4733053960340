import { combineReducers } from 'redux';

import login from './loginReducer';
import modalReducer from './modalReducer';
import usersReducer from './usersReducer';
import busqueda  from  './busquedaReducer';
const rootReducer = combineReducers({
  login,
  modalReducer,
  usersReducer,
  busqueda

});

export default rootReducer;
