import React, { Component } from 'react';
import password from '../../assets/contraseña/icon.svg';
import { loginAttemp } from '../../Actions'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import iicon from '../../assets/logo/comsicon.png';
import './index.css';
import { Button, Card, Modal, ModalBody, ModalFooter, ModalHeader, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, FormFeedback } from 'reactstrap';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      redirect: false,
      loading: false,
      register: false,
      errorMensaje: '',
      error: {
        usuario: '',
        contraseña: ''
      },
      errorLog: false,
      isLoggedin: false,
      contra: false,
      passVisible: false
    }
  }

  /*validateEmail =(email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }*/
  validate = (data) => {
    let errores = {
      usuario: '',
       contraseña: ''
    }
    if (!this.validateEmail(data.username)) {
      errores.usuario = "Error en el correo";
      this.setState({ error: errores });
      if (data.password !== '') {
        errores.contraseña = 'Error la contraseña deberia ser de mas de 5 caracteres';
        //this.setState({error:errores});
      } else {
        errores.contraseña = "";
        this.setState({ error: errores })
        return 1;
      }
    } else {
      if (data.password !== '') {
        errores.contraseña = 'Error la contraseña deberia ser de mas de 5 caracteres';
        this.setState({ error: errores });
      } else {
        errores.contraseña = "";
        this.setState({ error: errores });
        return 1;
      }
      errores.usuario = "";
      errores.contraseña = this.state.password;
      this.setState({ error: errores });
      return 1;
    }

  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit = () => {
    //this.validate(this.state);
    let { username, password } = this.state;
    let cred = {'mail':username,'password':password}
    console.log("submit")
    this.props.login(cred);
  }

  register = () => {
    this.setState({ register: true })
  }

  pass = () => {
    this.setState({ contra: true })
  }

  offmodal = () => {
    this.setState({ errorLog: false })
  }

  showPassword = () => {
    let { passVisible } = this.state;
    this.setState({ passVisible: !passVisible })
  }

  handleKeyPress = (target) => {
    if (target.charCode === 13) {
      this.onSubmit()
    }
  }

  render() {
    let { errorMensaje, passVisible } = this.state;
    

    if (this.props.cred) {
        return (<Redirect to={"/inicio"} />);
    }

    return (
      <div  >
        <Container fluid>
          <Row className="justify-content-center">
            <Col md="6" style={{paddingTop:'0%'}}>
            <img src={iicon} alt="iicon" style={{paddingTop:"100px", width:'100%', marginTop: "-3%"}} />
             </Col>
          </Row>
          <Container className="loginBox" style={{height: "100%" }} >
            <Modal isOpen={this.state.errorLog}
              className={'modal-sm ' + this.props.className}>
              <ModalHeader >Informacion</ModalHeader>
              <ModalBody>
                {errorMensaje}
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.offmodal}>Aceptar</Button>

              </ModalFooter>
            </Modal>
            <Row className="justify-content-center" >
              <Col md="6" style={{ top: "28%" }}>
                <CardGroup>
                  <Card className="p-4 loginBox" style={{ paddingTop: "200px", backgroundColor: "#f1f1f1" }}>
                    <CardBody>
                      <Form style={{ color: "black", textAlign: "center" }}>
                        <h2>Inicio de sesión</h2>
                        <p style={{ color: "black" }}>Ingresa los siguientes datos</p>
                        <InputGroup className="mb-3" >

                          <Input type="text" className="inputsRagasa" placeholder="Nombre de usuario" autoComplete="username" name="username" value={this.state.username} onChange={this.onChange} />


                        </InputGroup>
                        <InputGroup className="mb-4">

                          <Input type={passVisible ? "text" : "password"} onKeyPress={this.handleKeyPress} placeholder="Contraseña" autoComplete="current-password" name="password" value={this.state.password} onChange={this.onChange} invalid={this.props.error} />
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="inputsRagasaImg" >
                              <img src={password} alt="uno" onClick={this.showPassword} />
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormFeedback invalid style={{ fontWeight: "bold", color: "#fb5f5f" }}>
                            Credenciales no correctas
                        </FormFeedback>
                        </InputGroup>
                        <Row >
                          <Col sm="12" md={{ size: 12 }} >
                            <Button id="loginButton" className="loginButton" onClick={this.onSubmit} disabled={false}  >Iniciar sesión</Button></Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>

                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12" style={{paddingTop:'10%'}}>
                <p className="brand">Comscore</p>
              </Col>

            </Row>

          </Container>

        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cred: state.login.authed,
    error: state.login.error,
    username: state.login.username,
    role: state.login.role
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (credentials) => dispatch(loginAttemp(credentials)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);