export default {
    users: {
        data: [],
        createdUser: {
            _id: '',
            username: '',
            role: ''
        },
        updatedUser:{
            _id: '',
            username: '',
            role: ''
        },
        deletedUser: {
            _id: ''
        },
        resetedUser: {
            _id: ''
        },
        user:{},
        loading: false,
        errMessage: '',
        anios : []
    }
};
