import {INTENTO_BUSQUEDA,BUSQUEDA_SUC,BUSQUEDA_FAIL,SET_REGRESAR}  from '../../Constants';


export  function intentoBusqueda () {
    return {
        type:INTENTO_BUSQUEDA
    }
}

export  function failBusqueda () {
    return {
        type:BUSQUEDA_FAIL
    }
}

export  function sucBusqueda() {
    return {
        type:BUSQUEDA_SUC
    }
}

export  function setBusqueda (bus) {
    return {
        type:BUSQUEDA_SUC,
        bus:bus
    }
}


export  function setRegresar (reg) {
    return {
        type:SET_REGRESAR,
        reg:reg
    }
}

