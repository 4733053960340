export default {
    login:{
        loading: false,
        authed: false,
        key:"",
        hora:  (new Date()).getTime(),
        error: false,
        username:"",
        role:"",
        pswdReset: true,
        anio: false
    },
    modal:{
        modalVariable:false,
        modalReporte:false
    }
  };
