import React from 'react';
import { Modal,  ModalBody, ModalFooter } from 'reactstrap';
import {connect} from 'react-redux';
import {Grid,Col,Row} from 'react-flexbox-grid';
import {cambiaModalEstadistica} from '../../Actions/Modal'
import botonCerrar from '../../assets/botonCerrar/icon.svg';
import './index.css';

class ModalEstadistica extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        modal: this.props.modal,
        HTML:''
      };
  
      this.toggle = this.toggle.bind(this);
    }
  
    toggle() {
      let modal = this.props.modal;
      this.props.modalVariable(!modal);
      this.setState({modal:this.props.modal})
    }
    componentDidMount = () => {

      console.log("estoy en estadistica")
      let ciudad = localStorage.getItem("estciudadModal");
      let anio = localStorage.getItem("anio");
      fetch(`./data/${anio}/nav/estadistica/${ciudad}/index.html`).then(r => {
        r.text().then(t =>{
          console.log("got it")
            this.setState({HTML:t});
        })
    }
    ).catch((ex)=>{
        console.log("Error")
        console.log(ex)
    });
    } 
  
    render() {
      let modal = this.props.modal;
      let {HTML} = this.state;
      
      return (
        <div>
          <Modal isOpen={modal}  id="estadistica" >
         
          <div className="headerContent">
            <Grid >
            <Row>
              <Col xs={12}>
                <Row end="xs">
                  <a download={true} href={`./data/${localStorage.getItem("anio")}/est/EstadisticaMundial.xlsx`} className="botoncito"> Descargar </a>
                  <Col xs={15} /><img  style={{cursor:"pointer",padding:'2px'}}src={botonCerrar} alt="ing" onClick={this.toggle} />
                </Row>
              </Col>
            </Row> 
            </Grid>
            </div>

            <ModalBody className="scrollModal">
              <img src={`./data/${localStorage.getItem("anio")}/est/est.png`}></img>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
  const mapStateToProps = state => {
    return {
      modal : state.modalReducer.modalEstadistica
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      modalVariable:(modal)=> dispatch(cambiaModalEstadistica(modal)) 
    }
  }

export default connect (mapStateToProps,mapDispatchToProps)(ModalEstadistica);
