import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import './index.css'
import { connect } from 'react-redux';
import {  Redirect } from 'react-router-dom';
import directory from '../../Directory/directory.json';

import coms from '../../assets/nav/comscore.jpg';
class Menu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            topicName: 'algo',
            groupName: 'otro',
            pastaDate: '',
            pastaValor: 'ALGO',
            modeloDate: '',
            modeloValor: '',
            accesos: ['Mexico']
        }

    }

    timeConverter = (UNIX_timestamp) => {
        var a = new Date(UNIX_timestamp);
        var year = a.getFullYear();
        var month = a.getMonth() + 1;
        var date = a.getDate();
        var hour = a.getHours() + 5;
        var min = a.getMinutes();
        var sec = a.getSeconds();
        if (hour > 24) {
            hour = 24 - hour;
            date = date + 1;
        }
        if (date < 10) {
            date = "0" + date;
        }
        if (month < 10) {
            month = "0" + month;
        }
        if (hour < 10) {
            hour = "0" + hour;
        }
        if (min < 10) {
            min = "0" + min;
        }
        if (sec < 10) {
            sec = "0" + sec;
        }
        var time = `${year}-${month}-${date} ${hour}:${min}:${sec}`
        return time;
    }

    componentDidMount = () => {
        let anio = localStorage.getItem("anio");
        
        let content = directory.map(x=>{
            if(x.year===anio){
                return x.content
            }
        })[0]
        let ciudades = content.map(x => {
            return x.name
        })
        this.setState({ accesos: ciudades })

    }

    componentWillReceiveProps = (newProps) => {
    }
    subMenu = (ciudad) => {
        localStorage.setItem("city", ciudad);

        localStorage.setItem("regresar", "/inicio");
        this.setState({ submenu: true })

    }

    render() {
        let { submenu, accesos } = this.state;
        if (submenu) {
            return (<Redirect to={"/selector"} />);
        }
        return (
            <div style={{ width: "100%" }}>
                <Row>
                    <Col sm="12" md={{ size: 2, offset: 5 }}>
                        <span className="cabeza"><b>Comparativo de cine</b></span>
                    </Col>
                </Row>
                <Row >
                    {accesos.map((x, idc) => {
                        if (idc < 2) return (<Col style={{ borderRadius: "black 1px" }}>
                            <Row>
                                <Col  >
                                    <Row>

                                        <span className="menuCabeza"><b>{x}</b></span>
                                    </Row>
                                    <Row>
                                        <b><span className="yearBefore">2019</span><span className="contra"> VS </span><span className="CurrentDate">2020</span></b>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md={{ size: 6 }}><b>Descargar</b></Col>
                                        <Col sm="12" md={{ size: 6 }}><Button id="descargaBoton" className="descargaBoton" onClick={() => this.subMenu(x)} disabled={false}  >Entrar</Button></Col>

                                    </Row>
                                </Col>
                                <Col>

                                    <Row><Col><span className='imageTitle'>{x}</span></Col></Row>
                                    <Row><img src={coms} style={{ width: "97%" }}></img></Row>

                                </Col>

                            </Row>

                        </Col>)

                    })}
                </Row>
                <Row > {accesos.map((x, idc) => {
                    if (idc >= 2) return (<Col style={{ borderRadius: "black 1px" }}>
                        <Row>
                            <Col  >
                                <Row>

                                    <span className="menuCabeza"><b>{x}</b></span>
                                </Row>
                                <Row>
                                    <b><span className="yearBefore">2019</span><span className="contra"> VS </span><span className="CurrentDate">2020</span></b>
                                </Row>
                                <Row>
                                    <Col sm="12" md={{ size: 6 }}><b>Descargar</b></Col>
                                    <Col sm="12" md={{ size: 6 }}><Button id="descargaBoton" className="descargaBoton" onClick={() => this.subMenu(x)} disabled={false}  >Entrar</Button></Col>

                                </Row>
                            </Col>
                            <Col>

                                <Row><Col><span className='menuCabeza'>{x}</span></Col></Row>
                                <Row><img src={coms} style={{ width: "97%" }}></img></Row>
                            </Col>

                        </Row>

                    </Col>)

                })}
                </Row >
            </div>
        );
    }
};

const stateToProps = state => {
    return {
        authed: state.login.authed
    }
}

const dispatchToProps = dispatch => {
    return {

    }
}
export default connect(stateToProps, dispatchToProps)(Menu);
