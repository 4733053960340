import initialState from '../busquedaState';
import {BUSQUEDA_FAIL,BUSQUEDA_SUC,INTENTO_BUSQUEDA ,SET_REGRESAR} from '../../Constants';
import { updateObject } from '../utility';


export default function login(state = initialState.busqueda, action) {
    switch (action.type) {

        case BUSQUEDA_FAIL:
            return updateObject(state, {
                loading: action.loading
            })

        case BUSQUEDA_SUC:
            return updateObject(state, {
                busqueda:action.bus
            })

        case SET_REGRESAR:
                return updateObject(state, {
                    regresar:action.reg
                })

        case INTENTO_BUSQUEDA:
            return updateObject(state, {
                loading: action.loading
            })

        default:
            return state
    }
}
