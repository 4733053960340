import React from 'react';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap';
import PropTypes from 'prop-types';
import icon from '../../assets/logo/comsicon.png';
import bIcon from '../../assets/logo/comsicon.png';
import bbutt from '../../assets/nav/back.jpg';
import analisis from '../../assets/nav/analisis.jpg';
import est from '../../assets/nav/estMundial.jpg';
import menu from '../../assets/nav/menu.jpg';
import contacto from '../../assets/nav/mail.jpg';
import busquedaIcon from '../../assets/nav/busqueda.jpg';
import inicio from '../../assets/botones/inicio.png';
import usuarios from '../../assets/botones/usuarios.png';
import accessos from '../../assets/botones/acceso.png';
import usos from '../../assets/botones/uso.png';
import filmm2020 from '../../assets/nav/filmm2020.png';
import filmm2021 from '../../assets/nav/filmm2021.png';
import filmm2022 from '../../assets/nav/filmm2022.png';

import filmm2023 from '../../assets/nav/filmm2023.png';

import marc from '../../assets/nav/MARC.png';
import postrak from '../../assets/nav/PostTrak.png';
import salida from '../../assets/botones/salir.png';
import { connect } from 'react-redux';
import { cerrarSesion } from '../../Actions/';
import { cambiaModalSesion, cambiaModalAnalitica, cambiaModalEstadistica, modalNotas, modalAnot, setAnalisisData, setEstadisticaData ,cambiaModalMarc} from '../../Actions/Modal';
import { Link } from 'react-router-dom';
import ModalSesion from '../ModalLogin';
import ModalEstadistica from '../ModalEstadistica';
import ModalAnalisis from '../ModalAnalisis';
import ModalGlosario from '../ModalGlosario';
import Busqueda from '../Busqueda'
import './index.css'
import { useHistory } from "react-router-dom";
import { setRegresar } from '../../Actions/Busqueda';
import filmPDF from './FILMM.pdf';
import film2021 from './FILMM2021.pdf';
import film2022 from './FILMM2022.pdf';
import film2023 from './FILMM2023.pdf';
import MARC from './MARC.pdf';
import posttrakPDF from './POSTRAK.pdf';
import posttrakPDF2023 from './POSTRAK_2023.pdf';
import ModalMarc from '../ModalMarc';
function HomePage() {
  const history = useHistory();

  return (
    <>
      <img alt="salida" onClick={() => history.go(-1)} style={{ width: "75px", padding: "5px" }} />

    </>
  );
}

class CustomNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.state = {
      modalSesion: false,
      red: false,
      dropdownOpen: false,
      dropdownOpenA: false,
      toAdmnPanel: false,
      estad: false,
      bus: false,
      role: '',
      regresar: false,
      anio: false,
      marc: false

    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  toggle2() {
    this.setState({
      dropdownOpenA: !this.state.dropdownOpenA
    });
  }

  cerrarSession = () => {

    let modalSesion = this.props.modal;
    this.setState({ modalSesion: !modalSesion })
    this.props.modalCerrar(!modalSesion)
    this.setState({ red: true });

  }
  componentDidMount = () => {
    let { role } = this.props;
    this.setState({ role: role })
  }
  estadistica = (ciudad) => {
    console.log("estadistica")
    console.log(ciudad)
    let estad = this.props.estad;
    localStorage.setItem("estciudadModal", ciudad)
    this.setState({ estad: !estad })
    this.props.modalEstadistica(!estad)
  }
  
  marc = (ciudad) => {
    console.log("MARCCCC")
    console.log(ciudad)
    let marc = this.props.marc;
    console.log("este es el marc")
    console.log(marc)
    localStorage.setItem("marcciudadModal", ciudad)
    this.setState({ marc: !marc })
    this.props.modalMarc(!marc)
  }
  analista = (ciudad) => {

    console.log("estadistica")
    console.log(ciudad)
    let modalSesion = this.props.anali;

    localStorage.setItem("analciudadModal", ciudad)
    this.props.dataAnalisis(ciudad);
    this.setState({ anali: !modalSesion });
    this.props.modalAnalisis(!modalSesion);
  }
  glosario = () => {

    let modalSesion = this.props.glos;
    this.setState({ glos: !modalSesion });
    this.props.modalAnalisis(!modalSesion);
  }
  busqueda = () => {

    this.setState({ bus: true });
  }
  componentWillReceiveProps = (NewProps) => {
    let { role, anio } = NewProps;
    /*
    console.log(role)
    console.log("navbar props");
    console.log(NewProps)
    console.log("navbar anio");
    console.log(anio);
    console.log(localStorage.getItem("anio"))*/
    if (anio && localStorage.getItem("anio")) {
      this.setState({ anio: true })
    }
  }
  goBack = () => {
    let reg = localStorage.getItem("regresar");
    console.log("regresar")
    console.log(reg)
    if (reg === undefined) {
      localStorage.setItem("regresar", "/inicio")
      this.setState({ to: "inicio" });
      this.props.regresar(true);
    }
    if (reg.length > 0) {
      this.setState({ to: reg });
      this.props.regresar(true);
    } else {
      this.setState({ to: "inicio" });
      this.props.regresar(true);
    }
  }
  timeConverter = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp);
    var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var time = hour + ':' + min + ' ' + date + ' ' + month + ' ' + year + ' ';
    return time;
  }
  render() {
    let { color, bColor, darkk, username, role, pswdReset, authed, anio } = this.props;
    let { bus, reg, regresar, to } = this.state;
    console.log("busqueda")
    console.log(bus)
    let iicon = icon;
    let letra = {
      color: "black",
      textColor: 'black'
    }
    let styles = {
      container: {
        fontFamily: 'lato'
      },
      background: {
        backgroundColor: 'white',
        color: 'black',
        borderBottom: '2px solid',
        textColor: "black",
        padding: "0px",
        textAlign: "left"
      },
      line: {
        borderLeft: '1px solid #77d700',
        height: '30px',
        display: 'inline-block',
        marginTop: '2px',
        paddingRight: "10px"
      },
    }
    if (darkk) {
      iicon = icon;
      letra = {
        color: "white"
      }
    } else {
      iicon = bIcon;
      letra = {
        color: "#006741"
      }
    }

    return (
      <div style={styles.container}>
        <Navbar light={true} style={styles.background} expand="md">
          <NavbarBrand  >
            <Link to="inicio" style={letra} className="Navliga">
              <img src={iicon} alt="iicon" className="Bicon" />
            </Link>
          </NavbarBrand>
          {authed ?

            <div>
              {role == 'ADMN' ?
                <div>

                  <Nav navbar>
                    <NavItem>
                      <img src={inicio} alt="salida" onClick={this.goBack} className="imgNav" />
                      <span className="imgText">Inicio</span>
                    </NavItem>
                    <NavItem>
                      <Link to="inicio" style={letra} className="Navliga">
                        <img src={usuarios} alt="salida" className="imgNav" />
                      </Link>
                      <span className="imgText">Usuario</span>
                    </NavItem>
                    <NavItem>
                      <Link to="accesos" style={letra} className="Navliga">
                        <img src={accessos} alt="salida" className="imgNav" />
                      </Link>
                      <span className="imgText">Acessos</span>
                    </NavItem>

                    <NavItem>
                      <Link to="accesos" style={letra} className="Navliga">
                        <img src={usos} alt="salida" className="imgNav" />
                      </Link>
                      <span className="imgText">Uso</span>
                    </NavItem>

                    <NavItem>
                      <img src={salida} alt="salida" onClick={() => { this.cerrarSession() }} className="imgNav" />

                      <span className="imgText">Salir</span>
                    </NavItem>

                  </Nav>
                </div> :
                <div>
                  <Nav navbar>
                    {anio ?
                      <div className='child' style={{ display: 'inherit' }}>
                        <NavItem>
                          <img src={est} alt="salida" className="estImg" onClick={() => this.estadistica("mexico")} />
                        </NavItem>
                        <NavItem>
                          <Dropdown id="dropp" nav="true" isOpen={this.state.dropdownOpenA} toggle={this.toggle2} >
                            <DropdownToggle color="white" style={{ padding: '0 px' }} id="dropp">

                              <img src={analisis} className="anaImg" />
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem onClick={() => this.analista("mexico")}>México</DropdownItem>
                              <DropdownItem onClick={() => this.analista("centroamerica")}>Centroamerica</DropdownItem>
                              <DropdownItem onClick={() => this.analista("sudamerica")}>Sudamerica</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>

                        </NavItem>

                        {anio == "2023" ?
                          <NavItem style={{ paddingTop: "1.57%" }} >
                            <a className="postrak" download={true} href={posttrakPDF2023} ><img className="postrak" src={postrak} download={true} href={posttrakPDF2023} /></a>
                          </NavItem>
                          : <NavItem style={{ paddingTop: "1.67%" }} >
                          <a className="postrak" download={true} href={posttrakPDF} ><img className="postrak" src={postrak} download={true} href={posttrakPDF} /></a>
                        </NavItem>}
                        
                        {anio == "2023" ?
                          <NavItem style={{ paddingTop: "1.57%" }} >
                            <a className="marc" download={true} href={MARC}  ><img className="marc" src={marc} download={true} href={MARC} /></a>
                          </NavItem>
                          : <></>}
                        {anio == "2023" ?
                          <NavItem >
                            <a className="filmm2023" download={true} href={film2023} ><img className="filmm2023" src={filmm2023} download={true} href={filmPDF} /></a>
                          </NavItem>
                          : <></>}

                        {anio == "2020" ?
                          <NavItem>
                            <a className="filmm" download={true} href={filmPDF} ><img className="filmm" src={filmm2020} download={true} href={filmPDF} /></a>
                          </NavItem> : <></>}
                        {anio == "2021" ? <NavItem>
                          <a className="filmm" download={true} href={film2021} ><img className="filmm" src={filmm2021} download={true} href={filmPDF} /></a>
                        </NavItem> : <></>}
                        {anio == "2022" ? <NavItem>
                          <a className="filmm" download={true} href={film2022} ><img className="filmm" src={filmm2022} download={true} href={filmPDF} /></a>
                        </NavItem> : <></>}
                      </div>
                      : null}


                    <NavItem>
                      <img src={bbutt} alt="salida" className="navImages" onClick={this.goBack} />
                    </NavItem>
                    <NavItem>
                      {anio ?
                        <Link to="menu" style={letra} className="Navliga">
                          <img src={menu} alt="salida" className="navImages" />
                        </Link>
                        : null}
                    </NavItem>
                    <NavItem>
                      <Link to="contacto" style={letra} className="Navliga">
                        <img src={contacto} alt="salida" className="navImages" />
                      </Link>
                    </NavItem>

                    <NavItem>
                      {!bus ? <img src={busquedaIcon} onClick={this.busqueda} alt="salida" className="navImages" /> : <Busqueda />}
                    </NavItem>
                    <NavItem>
                      <img src={salida} alt="salida" onClick={() => { this.cerrarSession() }} className="navImages" />

                    </NavItem>

                  </Nav>
                </div>}
            </div> : null}
        </Navbar>
        <ModalSesion />
        <ModalEstadistica />
        <ModalAnalisis />
        <ModalGlosario />
        <ModalMarc/>
      </div>
    );
  }
}

CustomNavbar.propTypes = {
  color: PropTypes.string,
  bColor: PropTypes.string,
  dark: PropTypes.bool
};
const mapStateToProps = state => {
  return {
    fecha: state.login.hora,
    authed: state.login.authed,
    modal: state.modalReducer.modalSession,
    estad: state.modalReducer.modalEstadistica,
    marc: state.modalReducer.modalMarc,
    anali: state.modalReducer.modalAnal,
    glos: state.modalReducer.modalGlos,
    username: state.login.username,
    role: state.login.role,
    pswdReset: state.login.pswdReset,
    anio: state.login.anio
  }
}
const mapDispatchToProps = dispatch => {
  return {
    cerrarSession: () => dispatch(cerrarSesion()),
    modalCerrar: (modal) => dispatch(cambiaModalSesion(modal)),
    modalEstadistica: (modal) => dispatch(cambiaModalEstadistica(modal)),
    modalMarc: (modal) => dispatch(cambiaModalMarc(modal)),
    modalAnalisis: (modal) => dispatch(cambiaModalAnalitica(modal)),
    modalNotas: (modal) => dispatch(modalNotas(modal)),
    modalAnot: (modal) => dispatch(modalAnot(modal)),
    dataAnalisis: (ciudad) => dispatch(setAnalisisData(ciudad)),
    dataEstadistica: (ciudad) => dispatch(setEstadisticaData(ciudad)),
    regresar: (reg) => dispatch(setRegresar(reg))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomNavbar)

