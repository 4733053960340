import initialState from '../usersState';
import { GETUSERS_FAIL, GETUSERS_SUC, GETUSERS_TRY, CREATEUSERS_TRY, CREATEUSERS_SUC, CREATEUSERS_FAIL, EDITUSERS_FAIL, EDITUSERS_SUC, EDITUSERS_TRY, DELETEUSERS_FAIL, DELETEUSERS_SUC, DELETEUSERS_TRY, RESETUSER_TRY, RESETUSER_FAIL, RESETUSER_SUC , GETANUARIOS_SUC,GETANUARIO_FAIL,GETANUARIO_TRY,MODANUARIO_FAIL,MODANUARIO_SUC,MODANUARIO_TRY, GETANUARIOS_FAIL, GETANUARIO_SUC} from '../../Constants';
import { updateObject } from '../utility';


export default function login(state = initialState.users, action) {
    switch (action.type) {

        case GETUSERS_TRY:
            return updateObject(state, {
                loading: action.loading
            })

        case GETUSERS_FAIL:
            return updateObject(state, {
                loading: action.loading
            })

        case GETUSERS_SUC:
            let usersArr = action.users
            console.log(action)
            return updateObject(state, {
                loading: action.loading,
                data: usersArr,
            })

        case CREATEUSERS_TRY:
            return updateObject(state, {
                loading: action.loading
            })

        case CREATEUSERS_SUC:
            return updateObject(state, {
                loading: action.loading,
                createdUser: action.createdUser
            })

        case CREATEUSERS_FAIL:
            return updateObject(state, {
                errMessage: action.errMessage
            })


        case EDITUSERS_TRY:
            return updateObject(state, {
                //loading: action.loading
            })

        case EDITUSERS_SUC:
            return updateObject(state, {
                loading: action.loading,
                updatedUser: action.updatedUser
            })

        case EDITUSERS_FAIL:
            return updateObject(state, {
                errMessage: action.errMessage
            })


        case DELETEUSERS_TRY:
            return updateObject(state, {
                loading: action.loading
            })

        case DELETEUSERS_SUC:
            return updateObject(state, {
                loading: action.loading,
                deletedUser: action.deletedUser
            })

        case DELETEUSERS_FAIL:
            return updateObject(state, {
                errMessage: action.errMessage
            })

        case RESETUSER_TRY:
            return updateObject(state, {
                loading: action.loading
            })

        case RESETUSER_FAIL:
            return updateObject(state, {
                loading: action.loading
            })

        case RESETUSER_SUC:
            return updateObject(state, {
                loading: action.loading,
                resetedUser: action.resetedUser
            })
        case RESETUSER_SUC:
                return updateObject(state, {
                    loading: action.loading,
                    resetedUser: action.resetedUser
                })
        case GETANUARIO_TRY:
            return updateObject(state, {
                loading: action.loading
            })

        case GETANUARIO_FAIL:
            return updateObject(state, {
                loading: action.loading
            })

        case GETANUARIO_SUC: 
            let anios  = action.anuarios.map(x=>{
                return x.anuario.anio
            })
            return updateObject(state, {
                loading: action.loading,
                anios: anios,
            })

        default:
            return state
    }
}
