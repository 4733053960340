import initialState from '../initialState';

import { LOGIN_FAIL, LOGIN_TRY, LOGIN_SUC, UPDATEPASSWORD_FAIL, UPDATEPASSWORD_TRY, UPDATEPASSWORD_SUC, CERRAR_SESSION, SET_ANIO } from '../../Constants';

import { updateObject } from '../utility/index';

export default function login(state = initialState.login, action) {

  switch (action.type) {
    case LOGIN_FAIL:
      return updateObject(state, {
        loading: action.loading,
        authed: false,
        error: action.error
      })
    case LOGIN_TRY:
      return updateObject(state, {
        loading: action.loading
      });
    case LOGIN_SUC:
      return updateObject(state, {
        loading: false,
        authed: true,
        key: action.xkey,
        username: action.username,
        role: action.role,
        pswdReset: action.pswdReset
      });
    case UPDATEPASSWORD_FAIL:
      return updateObject(state, {
        loading: action.loading,
      })
    case UPDATEPASSWORD_TRY:
      return updateObject(state, {
        loading: action.loading,
      });
    case UPDATEPASSWORD_SUC:
      return updateObject(state, {
        loading: action.loading,
        pswdReset: action.pswdReset
      });

    case CERRAR_SESSION:
      return Object.assign({}, state, {
        loading: false,
        authed: false,
        key: "",
      })
    case SET_ANIO:
      return Object.assign({}, state, {
        loading: false,
        anio: action.anio,
        key: "",
      })
    default:
      return state;
  }
}
