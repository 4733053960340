export default {
    modal:{
        modalEstadistica:false,
        modalMarc:false,
        modalAnal:false,
        modalGlos:false,
        modalSession:false,
        modalRestablecer:false,
        modalNotas:false,
        modalAnot:false,
        resDat:false,
        dataAnot:[],
        dataNotas:[],
        dataRef:[],
        modalRef:false,
        dataAnal:'',
        dataEstad:''
    }
  };
