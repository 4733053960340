import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { Grid, Col, Row } from 'react-flexbox-grid';
import { cambiaModalAnalitica } from '../../Actions/Modal'
import botonCerrar from '../../assets/botonCerrar/icon.svg';
import './index.css'

class ModalAnalisis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      ciudad: '',
      HTML: ''
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    let modal = this.props.modal;
    this.props.modalVariable(!modal);
    this.setState({ modal: this.props.modal })
  }
  componentDidMount = () => {
    let ciudad = this.props.ciudad;
    this.setState({ ciudad: ciudad })
    fetch(`./data/nav/analisis/${ciudad}/index.html`).then(r => {
      r.text().then(t => {
        this.setState({ HTML: t });
      })
    }
    ).catch((ex) => {
      console.log(ex)
    });
  }
  componentWillUpdate = (newprops) => {
    let { ciudad } = this.state;

    let propCiudad = newprops.ciudad;
    let anio = localStorage.getItem("anio");
    if (ciudad != propCiudad) {
      this.setState({ ciudad: propCiudad });
      fetch(`./data/${anio}/nav/analisis/${propCiudad}/index.html`).then(r => {
        r.text().then(t => {
          this.setState({ HTML: t });
        })
      }
      ).catch((ex) => {
        console.log(ex)
      });

    }

  }

  render() {
    let modal = this.props.modal;
    let { HTML } = this.state;

    return (
      <div>
        <Modal isOpen={modal} id='estadistica' >

          <div className="headerContent">
            <Grid >
              <Row>
                <Col xs={12}>
                  <Row end="xs">
                    <Col xs={15} /><img style={{ cursor: "pointer" }} src={botonCerrar} alt="ing" onClick={this.toggle} />
                  </Row>
                </Col>
              </Row>
            </Grid>
          </div>

          <ModalBody className="scrollModal">
            <div dangerouslySetInnerHTML={{ __html: HTML }} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    modal: state.modalReducer.modalAnal,
    ciudad: state.modalReducer.dataAnal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    modalVariable: (modal) => dispatch(cambiaModalAnalitica(modal))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAnalisis);
