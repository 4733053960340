import Loadable from 'react-loadable'
import Loading from '../../Views/Loading';
import Menu from '../../Views/Menu';

const Home = Loadable({
    loader: () => import('../../Views/Home'),
    loading: Loading,
  });

  const selector = Loadable({
    loader: () => import('../../Views/Selector'),
    loading: Loading,
  });
  const splash = Loadable({
    loader: () => import('../../Views/Splash'),
    loading: Loading,
  });

  const launcher = Loadable({
    loader: () => import('../../Views/Launcher'),
    loading: Loading,
  });

  const graph = Loadable({
    loader: () => import('../../Views/Graph'),
    loading: Loading,
  });

  const Contacto = Loadable({
    loader: () => import('../../Views/Contacto'),
    loading: Loading,
  });

  const geo = Loadable({
    loader: () => import('../../Views/Geo'),
    loading: Loading,
  });


  const Usuarios = Loadable({
    loader: () => import('../../Views/Usuarios'),
    loading: Loading,
  });
  const Viewer = Loadable({
    loader: () => import('../../Views/Viewer'),
    loading: Loading,
  });
  const Demo = Loadable({
    loader: () => import('../../Views/Map'),
    loading: Loading,
  });
const routes = [
    { path:'/inicio',name:"Inicio", component: splash  },
    { path:'/selector',name:"Selector", component: selector },
    { path:'/launcher',name:"Launcher", component: launcher },
    { path:'/graph',name:"Launcher", component: graph },
    { path:'/contacto',name:"Contacto", component: Contacto },
    { path:'/menu',name:"Menu", component: Home },
    { path:'/users',name:"Users", component: Usuarios },
    { path:'/view',name:"View", component: Viewer },
    { path:'/demografico',name:"Demo", component: Demo },

    
]

export default routes;