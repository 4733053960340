import initialState from '../modalState';

import {
  MODAL_ESTADISTICA, MODAL_ANALITICA, MODAL_LOGIN, RES_DAT,
  RES_MODAL, MODAL_NOTA, MODAL_ANOT, MODAL_GLOSARIO, DATA_REF,
  DATA_ANOT, DATA_NOTA, MODAL_REF, SET_ESTAD, SET_ANALITIC, MODAL_MARC
} from '../../Constants';

import { updateObject } from '../utility/index';
export default function login(state = initialState.modal, action) {
  switch (action.type) {
    case MODAL_ESTADISTICA:
      return updateObject(state, {
        modalEstadistica: action.modal
      })

    case MODAL_MARC:
      return updateObject(state, {
        modalMarc: action.modal
      })
    case MODAL_NOTA:
      return updateObject(state, {
        modalNotas: action.modal
      })
    case MODAL_ANOT:
      return updateObject(state, {
        modalAnot: action.modal
      })
    case MODAL_REF:
      return updateObject(state, {
        modalRef: action.modal
      })
    case MODAL_ANALITICA:
      return updateObject(state, {
        modalAnal: action.modal
      })

    case DATA_ANOT:
      return updateObject(state, {
        dataAnot: action.anot
      })
    case DATA_REF:
      return updateObject(state, {
        dataRef: action.ref
      })
    case DATA_NOTA:
      return updateObject(state, {
        dataNotas: action.nota
      })

    case MODAL_GLOSARIO:
      return updateObject(state, {
        modalGlos: action.modal
      })
    case MODAL_LOGIN:
      return updateObject(state, {
        modalSession: action.modal
      })
    case RES_DAT:
      return updateObject(state, {
        resDat: action.modal
      })
    case RES_MODAL:
      console.log(action.modal)
      return updateObject(state, {
        modalRestablecer: action.modal
      })
    case SET_ANALITIC:
      return updateObject(state, {
        dataAnal: action.archivo,
      })
    case SET_ESTAD:
      return updateObject(state, {
        dataEstad: action.archivo,
      })
    default:
      return state;
  }
}

